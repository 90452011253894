<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}角色</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" ref="form">

                <el-form-item label="父级用户" prop="parentId" ref="parentId">
                    <Treeselect :normalizer="normalizer" @input="changeParentId" placeholder="请选择父级用户" v-model="form.parentId" :options="treeselectOptions" />
                </el-form-item>
                <el-form-item label="角色名称" prop="name" ref="name">
                    <el-input v-model="form.roleName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item label="角色标识" prop="name" ref="name">
                    <el-input v-model="form.roleType" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
    components: {
      Treeselect: () => import('@riophae/vue-treeselect'),
    },

    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        treeselectOptions: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: false,
            submitLoading: false,
            form: {},
            tableData:{},
        };
    },
    inject:['refresh'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal){
                this.form = newVal;
            }
        }
    },
    created() {
    },
    methods: {
        submit(){
            if(this.options.id){
              this.$refs.form.validate((valid,obj) => {
                  if (valid) {
                      this.submitLoading = true;
                      this.$api.role.edit(this.form).then( res => {
                          this.submitLoading = false;
                          this.refresh(1);
                          this.$message.success("操作成功！");
                      
                      }).catch(err=>{
                          this.submitLoading = false;
                          console.log(err);
                      })
                  }else {
                      this.scrollView(obj)
                  };
              });   
            }else{
              this.$refs.form.validate((valid,obj) => {
                  if (valid) {
                      this.submitLoading = true;
                      this.$api.role.add(this.form).then( res => {
                          this.submitLoading = false;
                          this.refresh(1);
                          this.$message.success("操作成功！");
                      
                      }).catch(err=>{
                          this.submitLoading = false;
                          console.log(err);
                      })
                  }else {
                      this.scrollView(obj)
                  };
              });              
            }


        },
        changeParentId(){
            this.$refs.form.validateField('parentId');
        },
        normalizer(node) {
            return {
                id: node.id,
                label: node.roleName,
                children: node.children,
            }
        },


    },
};
</script>
